import prism from "./prism";

export default {
  prism,

  primary: "#27292D",
  secondary: "rgba(39, 41, 45, 0.74)",
  grey: "#83878E",
  background: "#F0F0F4",
  backgroundDark: "#090A0B",
  backgroundModal: "rgba(209, 232, 235, 0.98)",
  cleanBackground: "#FFFFFF",
  textPrimaryDark: "#EDEBEB",
  textSecondaryDark: "rgba(236, 235, 237, 0.8)",
  card: "#fff",
  cardDark: "#111314",
  accent: "#4834FB",
  // accent: "#7047EB",

  decorColor01: "#C7C8CA",
  decorColor02: "#A4BEB2",
  decorColor03: "#EACA96",
  decorColor04: "#EEB8B0",
  decorColor05: "#ACADCA",
  decorColor06: "#A9BED3",

  hover: "rgba(0, 0, 0, 0.07)",
  gradient: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
  gradientImage: "linear-gradient(180deg, rgba(209, 232, 235, 0) 0%, rgba(209, 232, 235, 0.95) 71.88%, #D1E8EB 100%)",
  track: "rgba(8, 8, 11, 0.3)",
  progress: "#000",
  error: "#EE565B",
  success: "#46B17B",
  errorBackground: "rgba(238, 86, 91, 0.1)",
  horizontalRule: "rgba(61, 96, 102, 0.2)",
  inputBackground: "rgba(0, 0, 0, 0.05)",
  pureBlack: "#000000",
  neumorphismShadown: "10px 10px 22px rgba(255, 255, 255, 0.28), -10px -10px 24px rgba(255, 255, 255, 0.65)",
  modes: {
    dark: {
      primary: "#201E20",
      grey: "#73737D",
      secondary: "#201E20",
      accent: "#E9DAAC",
      background: "#F4F4F4",
      backgroundModal: "rgba(17, 18, 22, 0.95)",
      cleanBackground: "#111216",
      hover: "rgba(255, 255, 255, 0.07)",
      gradient:
      "linear-gradient(180deg, #111216 0%, rgba(66, 81, 98, 0.36) 100%)",
      articleText: "#201E20",
      track: "rgba(255, 255, 255, 0.3)",
      progress: "#fff",
      card: "#1D2128",
      error: "#EE565B",
      success: "#46B17B",
      errorBackground: "rgba(238, 86, 91, 0.1)",
      horizontalRule: "rgba(255, 255, 255, 0.15)",
      inputBackground: "rgba(255, 255, 255, 0.07)",
      textTitle: "#fff",
      neumorphismShadown: "none",
    },
  },
};
